import usePiggy from "./usePiggy";
import useSockets from "./useSockets";

type EventData = {
	action: "topUp" | "break" | "collectUp";
	data: {
		amount?: number;
		moneyBoxAmount?: number;
		status?: string;
		accumulatedAmount?: number;
		availableTimeToBreak?: string | null;
	};
};

type ModalOpen<K extends string, P> = (name: K, props?: P) => void;

const usePiggySocket = <K extends string, P>(open: ModalOpen<K, P>) => {
	const { updateMoneyBox, updateTimerLimit, updateStatus, updateBalanceByBet, updateBalanceByCollect } = usePiggy();
	const { add, remove } = useSockets();

	add("moneyBox", ({ action, data }: EventData) => {
		if (action === "break") {
			updateTimerLimit(data?.availableTimeToBreak ?? "");
			updateMoneyBox(data?.moneyBoxAmount ?? 0);
			updateStatus(data?.status ?? "");
			open("LazyOModalPiggyBankPrizeWin" as K, { prizeWin: data?.accumulatedAmount ?? 0 } as P);
		}

		if (action === "collectUp") {
			updateBalanceByCollect(data?.amount ?? 0);
			updateStatus(data?.status ?? "");
		}

		if (action === "topUp") {
			updateBalanceByBet(data?.moneyBoxAmount ?? 0);
			updateStatus(data?.status ?? "");
		}
	});

	onUnmounted(() => {
		remove("moneyBox");
	});
};

export default usePiggySocket;
