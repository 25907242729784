import { useVibrate } from "@vueuse/core";
import { toast } from "vue3-toastify";

import OPrizeDropsTimeToast from "@/organizms/O/PrizeDrops/TimeToast.vue";

const usePrizeDropsToasts = () => {
	const { t } = useT();
	const route = useRoute();
	const isUserInGame = computed(() => route.query?.game);

	const { vibrate, isSupported } = useVibrate({ pattern: [200, 100, 200, 100, 200] });
	const { isDesktop } = useDevice();

	const toastId = ref();
	const intervalId = ref();

	if (toast.isActive(toastId.value)) {
		toast.remove(toastId.value);
	}

	const clearToastInterval = () => {
		if (intervalId.value) {
			clearInterval(intervalId.value);
		}
	};

	const showTimeToast = (initialTime: number) => {
		const toastProps = reactive({
			initialDuration: initialTime,
			title: t("prizeDrops.toastWithTimer.title"),
			text: t("prizeDrops.toastWithTimer.text"),
			btnText: t("prizeDrops.toastWithTimer.btn"),
			timerText: t("prizeDrops.toast.timeLeft")
		});

		clearToastInterval();
		toastProps.initialDuration = initialTime;

		intervalId.value = setInterval(() => {
			if (toastProps.initialDuration <= 0) {
				clearToastInterval();
				return;
			}

			toastProps.initialDuration -= 1;
			if (toastProps.initialDuration === 40 && !isUserInGame.value) {
				if (isSupported.value) {
					vibrate();
				}
				toastId.value = toast.success(h(OPrizeDropsTimeToast, toastProps), {
					containerId: "prize-receive-timer",
					icon: h("img", {
						src: "/nuxt-img/prize-drops/royal/crown-bronze.png",
						width: 40,
						height: 40
					}),
					position: isDesktop ? toast.POSITION.TOP_RIGHT : toast.POSITION.TOP_CENTER,
					transition: toast.TRANSITIONS.SLIDE,
					autoClose: 5000,
					onClick: () => {
						navigateTo("/issues/popular-games/");
					}
				});
			}
		}, 1000);
	};

	onBeforeUnmount(() => {
		clearToastInterval();
	});

	return {
		showTimeToast
	};
};
export default usePrizeDropsToasts;
