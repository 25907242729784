import { toast } from "vue3-toastify";

const usePrizeDropInit = () => {
	const { isDesktop } = useDevice();
	const { t } = useT();
	const { open } = useTaoModals();

	const { resetPrizeDropTimer, lastWinners, durationFinished, toNextPrizeDurationLeft, toNextPrizeDuration } =
		usePrizeDrop();
	provide("prizeDrop", {
		resetPrizeDropTimer,
		lastWinners,
		durationFinished,
		toNextPrizeDurationLeft,
		toNextPrizeDuration
	});
	const { showTimeToast } = usePrizeDropsToasts();
	const toastOptions = {
		position: isDesktop ? toast.POSITION.TOP_RIGHT : toast.POSITION.TOP_CENTER
	};

	onMounted(() => {
		usePrizeDropSocket({
			t,
			open,
			showTimeToast,
			toNextPrizeDurationLeft,
			resetTimer: resetPrizeDropTimer,
			toastOptions
		});
	});
};

export default usePrizeDropInit;
