const minDepositToShow = 20;
const firstCountToShow = 1;
const secondCountToShow = 2;

const usePopupsInit = () => {
	const { data: appData } = useAppInitData();
	const route = useRoute();
	const loginGuard = useLoginGuard();
	const { openDefault: openPresetPackagesPopup } = usePresetPackageController();
	const { handleOpenPopup } = usePromoInviteFriends();
	const { state: popupState, reset: resetPopupState } = usePopupsState();
	const { handleAsyncOpen: handleAsyncMagicBoxesOpen } = useMagicBoxesController();

	const increaseAndCheckReferral = (count: number, cb: () => void) => {
		popupState.value.referralPopup.stepsToShow += 1;
		if (popupState.value.referralPopup.stepsToShow === count) {
			cb();
		}
	};

	watch(
		() => appData.value?.isGuest,
		(value) => {
			if (value) {
				resetPopupState();
			}
			popupState.value.isLoginAfterRegistration = !popupState.value.isLoginFlow;
		}
	);

	watch([() => route.path, () => route.query], ([newPath], [oldPath, oldQuery]) => {
		loginGuard({
			success: () => {
				if (!!oldQuery?.social || !!oldQuery?.action) {
					popupState.value.loginAfterSocialReg = oldQuery.action === "register";
					popupState.value.loginBySocial = oldQuery.action === "login";
				}
				if (newPath === "/" && oldPath !== "/") {
					increaseAndCheckReferral(firstCountToShow, handleOpenPopup);

					if (!popupState.value.isLoginAfterRegistration) {
						if (popupState.value.loginAfterSocialReg || popupState.value.loginBySocial) {
							popupState.value.offerPopup.stepsToShow += 1;
							if (popupState.value.offerPopup.stepsToShow >= secondCountToShow) {
								popupState.value.magicBox = true;
								popupState.value.loginAfterSocialReg = false;
								return;
							}
							handleAsyncMagicBoxesOpen();
						}
						return;
					}
					if (!popupState.value.magicBox) {
						popupState.value.offerPopup.stepsToShow += 1;
						if (popupState.value.offerPopup.stepsToShow === secondCountToShow) {
							if (!popupState.value.isLoginFlow) {
								openPresetPackagesPopup();
								return;
							}
							if (!appData.value?.depositsSum) {
								return;
							}
							if (Number(appData.value.depositsSum) >= minDepositToShow) {
								handleOpenPopup();
							}
						}
						if (popupState.value.offerPopup.stepsToShow >= secondCountToShow) {
							popupState.value.magicBox = true;
							return;
						}
						handleAsyncMagicBoxesOpen();
					}
				}
			}
		});
	});

	onMounted(() => {
		if (route.query.action === "register") {
			popupState.value.loginAfterSocialReg = true;
		}
	});
};

export default usePopupsInit;
