const useAppScratchCards = () => {
	const { data: appInitData } = useAppInitData();
	const { open, close, isOpen } = useTaoModals();
	const loginGuard = useLoginGuard();
	const { openModalOnce, checkDailyOpen } = useWelcomeModal(open, close);
	const { data: scratchCardData, refresh } = useScratchCard({
		immediate: !!appInitData.value?.scratchCardLottery?.isActive
	});

	const isGuest = computed(() => appInitData.value?.isGuest);

	const handleScratchCardsPopups = () => {
		if (appInitData.value?.scratchCardLottery?.isActive) {
			openModalOnce({
				storage: "showWelcomeScratchCards",
				storageSkip: "showFeatureScratchCards",
				modal: "LazyOModalScratchCardsWelcome",
				endDate: scratchCardData.value?.finishedAt
			});
			if (!isOpen("LazyOModalScratchCardsWelcome")) {
				checkDailyOpen({ storage: "showFeatureScratchCards", modal: "LazyOModalScratchCardsFeature" });
			}
		}
	};

	watch(isGuest, (val) => {
		if (!val) {
			handleScratchCardsPopups();
		}
	});

	onMounted(() => {
		loginGuard({
			success: () => {
				handleScratchCardsPopups();
			}
		});
	});

	useEvent(["nuxt:scratchCardsRefresh"], () => {
		refresh();
	});
};

export default useAppScratchCards;
