const play = (
	id: NonNullable<Awaited<ReturnType<typeof useBonusesData>>["data"]["value"]>["audios"][number]["name"]
) => {
	const audio = document.getElementById(id) as HTMLAudioElement;
	if (audio) {
		audio.play();
	}
};

const pause = (
	id: NonNullable<Awaited<ReturnType<typeof useBonusesData>>["data"]["value"]>["audios"][number]["name"]
) => {
	const audio = document.getElementById(id) as HTMLAudioElement;
	if (audio) {
		audio.pause();
	}
};

const activeChestPauseDelay = 5000;

const useMagicBoxAudios = () => {
	const { data } = useBonusesData();

	const isSoundOn = useLocalStorage<undefined | "off" | "on">("isSoundOn", undefined);
	const isSoundOnActive = computed(() => isSoundOn.value === "on");

	const activeChestTimer = useState<ReturnType<typeof setTimeout> | number | null>("activeChestTimer", () => null);

	const id = computed(() => (data.value?.dailyChests?.available ? "soundActiveChests" : "soundDisabledChests"));

	const playMagicAudio = () => {
		if (!isSoundOnActive.value) {
			return;
		}
		play(id.value);
	};

	const playActiveChest = () => {
		if (!isSoundOnActive.value) {
			return;
		}
		pause("soundActiveChests");
		pause("soundDisabledChests");
		play("soundOpenChest");

		activeChestTimer.value = setTimeout(() => {
			pause("soundOpenChest");
			play("soundDisabledChests");
		}, activeChestPauseDelay);
	};

	const pauseAllChests = () => {
		if (activeChestTimer.value) {
			clearTimeout(activeChestTimer.value);
			activeChestTimer.value = null;
		}
		pause("soundActiveChests");
		pause("soundDisabledChests");
		pause("soundOpenChest");
	};

	const toggleSound = () => {
		isSoundOn.value = isSoundOn.value === "on" ? "off" : "on";
		pauseAllChests();
		if (isSoundOnActive.value) {
			playMagicAudio();
		}
	};

	onMounted(() => {
		if (!isSoundOn.value) {
			isSoundOn.value = "off";
		}
	});

	return {
		playActiveChest,
		playMagicAudio,
		play,
		pause,
		pauseAllChests,
		isSoundOn,
		toggleSound
	};
};

export default useMagicBoxAudios;
