import useIsRegistrationCompleteGuard from "./useIsRegistrationCompleteGuard";

type ModalOpen<K extends string> = (name: K) => void;

const useInitRegisterComplete = <K extends string>(open: ModalOpen<K>) => {
	const guardFunction = useIsRegistrationCompleteGuard();

	onMounted(() => {
		guardFunction({
			success: () => {
				open("LazyOModalRegistrationComplete" as K);
			}
		});
	});
};

export default useInitRegisterComplete;
