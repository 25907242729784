<script setup lang="ts">
useTheme("tao");
const route = useRoute();
const { data: appInitData } = useAppInitData();
const { t } = useT();
const { open } = useTaoModals();
const loginGuard = useLoginGuard();
const { checkPendingBonus } = useWithoutDepositBonus(open, t);

onMounted(() => {
	if ("scrollRestoration" in history) {
		history.scrollRestoration = "manual";
	}

	pushNotificationsDispatchEvent("init");
	if (appInitData.value?.captcha && appInitData.value?.captcha?.isEnabled && appInitData.value?.captcha?.key) {
		useHead({
			script: [
				{
					src: appInitData.value?.captcha?.url,
					id: appInitData.value?.captcha?.key,
					async: true,
					defer: true
				}
			]
		});
	}

	loginGuard({
		success: () => {
			if (route.query?.freeEntriesBonus) {
				checkPendingBonus(route.query?.freeEntriesBonus as string);
			}

			if (route.query?.game) {
				open("LazyOModalGame");
			}
		}
	});
});

useEvent(["LEAVE_CASH"], () => {
	setTimeout(() => {
		document.body.style.overflow = "initial";
	}, 300);
});

useInitRegisterComplete(open);
usePopupsInit();
useMagicBoxInit();
usePresetPackageInit();
useCheckPromoOffer(open);
useAppRefreshSocket(t);
useTournamentNotification();
useSeasonInit();
usePrizeDropInit();
useAppScratchCards();
useUploadDocumentsToast("dark", "/nuxt-img/account/success.png", "/nuxt-img/account/error.png");
usePiggySocket(open);
</script>
<template>
	<div>
		<NuxtPage />
		<ClientOnly>
			<SupportChat />
			<OModals />
		</ClientOnly>
	</div>
</template>

<style lang="scss">
.Toastify {
	&__toast-container {
		@include media-breakpoint-down(lg) {
			padding-right: 16px;
			left: 14px;
		}
	}
	&__toast {
		@include media-breakpoint-down(lg) {
			margin-bottom: 8px;
		}
	}

	&__toast:has(.badge) {
		overflow: visible;
	}

	.badge {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		top: 10px;
		left: -13px;
		width: 26px;
		height: 20px;
		border-radius: gutter(0.5);
		color: var(--coro);
		font-size: 12px;
		background-color: var(--camaguey);
		z-index: 123123;
	}
}

#email-confirm-container {
	width: 320px;
	max-width: 100%;

	@include media-breakpoint-down(md) {
		transform: translateX(-50%);
		left: 50%;
		top: 8px;
	}

	.Toastify {
		&__toast {
			background: var(--camaguey);
		}
	}
}

#notification-center-container,
#notification-center-container-email {
	width: 360px;
	max-width: 100%;

	@include media-breakpoint-down(lg) {
		bottom: auto;
		left: auto;
		top: 64px;
	}

	.icon-close {
		flex-shrink: 0;
		width: 18px;
		height: 18px;
		background: url("~/assets/icons/20/close.svg") center/cover no-repeat;
	}

	.notification {
		&-center {
			display: flex;
			flex-direction: column;
		}

		&-title {
			color: var(--gray-50);
			font-size: 14px;
			font-weight: 700;
			line-height: 18px;
			margin-bottom: 3px;
		}

		&-text {
			color: var(--gray-200);
			font-size: 12px;
			font-weight: 400;
			line-height: 16px;
			margin-bottom: 0;
		}

		&-more {
			color: var(--primary-400);
		}
	}

	.Toastify {
		&__toast {
			border-radius: 12px;
			background-color: var(--gray-900);
			padding: 16px;
			min-height: 65px;
		}
		&__toast-icon {
			width: 40px;
			margin-right: 8px;

			img {
				width: 40px;
				height: 40px;
			}
		}

		&__toast-body > div:last-child > div {
			display: flex;
			flex-direction: column;
		}

		&__close-button {
			display: block;
			color: var(--gray-200);
			opacity: 1;
		}
	}
}
.Toastify__toast--success.notification-quest-progress {
	max-width: 300px;
	margin-left: auto;

	@include media-breakpoint-down(lg) {
		margin-left: 0;
		bottom: auto;
		left: 12px;
		top: 76px;
		max-width: 300px;

		@media (orientation: landscape) {
			top: 12px;
			left: 60px;
		}
	}

	.Toastify {
		&__toast-body {
			flex-direction: row-reverse;
			align-items: flex-start;

			& > div:last-child {
				font-weight: 500;
				text-transform: uppercase;
				color: var(--cannes);
			}
		}

		&__toast-icon {
			margin: 0;
			width: auto;

			.icon-close {
				flex-shrink: 0;
				width: 12px;
				height: 12px;
				background: url("~/assets/icons/20/close.svg") center/cover no-repeat;
			}
		}
	}
}
.Toastify__toast--success.notification-season {
	width: 343px;
	margin-left: auto;
	height: 128px;
	background: var(--secondary-950);
	padding: 16px 12px 12px 137px;
	gap: 0;

	@include media-breakpoint-down(lg) {
		margin: 0 auto;
		top: 76px;

		@media (orientation: landscape) {
			top: 12px;
		}
	}

	.Toastify {
		&__toast-body {
			flex-direction: row-reverse;
			align-items: flex-start;
			height: 110px;

			& > div:last-child > div {
				display: flex;
				flex-direction: column;
			}
		}

		&__toast-icon {
			width: 154px;
			height: 128px;
			position: absolute;
			top: 0;
			left: 0;
			margin: 0;
		}

		&__close-button {
			svg {
				display: none;
			}

			display: block;
			width: 14px;
			height: 14px;
			flex-shrink: 0;
			background: url("~/assets/icons/14/close.svg") center/cover no-repeat;
			opacity: 1;
		}
	}

	.notification {
		&-center {
			display: flex;
			flex-direction: column;
		}

		&-title {
			color: var(--primary-400);
			font-size: 16px;
			font-weight: 600;
			text-transform: uppercase;
			margin-bottom: 8px;
		}

		&-text {
			font-size: 12px;
			line-height: 20px;
			color: var(--coro);
			display: flex;
			align-items: center;

			span {
				display: flex;
				align-items: center;
				gap: 4px;

				&::before {
					content: "";
					width: 20px;
					height: 20px;
					background: url("~/assets/icons/12/secret-coins.svg") center/cover no-repeat;
				}
			}
		}
	}
}

#upload-document-notification,
#upload-document-cash-notification {
	.Toastify__toast-icon {
		margin: 0;
		width: auto;

		.icon-close {
			visibility: hidden;
			width: 0;
		}
	}

	.Toastify__toast {
		min-width: 328px;
		width: 328px;
		top: 90px;
		height: auto;
		min-height: 54px;
		padding: 16px;
		border-radius: 10px;
		background: var(--success-950);
		box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.1);

		@media (max-width: 1024px) {
			width: 328px;
		}

		@media only screen and (max-width: 480px) {
			top: 110px;
		}

		.Toastify__toast-body {
			white-space: normal;
			margin: 0;
		}

		.toast-content-container {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 8px;

			&:has(.toast-message) {
				.toast-title {
					font-weight: 700;
				}
			}
		}

		.toast-img-container {
			display: flex;
			align-items: center;
		}

		.toast-img {
			width: 20px;
			height: 20px;
		}

		.toast-title {
			color: var(--gray-50);
			font-size: 14px;
			font-weight: 400;
			line-height: 22px;
		}

		.toast-message {
			color: var(--danger-200);
			font-size: 12px;
			font-weight: 400;
			line-height: 16px;
		}

		&--error {
			background-color: var(--danger-900);
		}
	}
}

#upload-document-cash-notification {
	.Toastify__toast {
		top: 0;

		@media only screen and (max-width: 480px) {
			top: 20px;
		}
	}
}

#paywithmybank-learnMoreDescription {
	z-index: 9999;

	#paywithmybank-learnMoreButton {
		background: var(--primary-400);
		color: var(--gray-950);
		min-height: 48px;
	}
}

.grecaptcha-badge {
	visibility: hidden;
}

#notification-prize-drops {
	.Toastify {
		&__toast {
			padding: 8px 16px;
			@include media-breakpoint-down(lg) {
				margin: 8px 0;
			}

			&-body {
				white-space: normal;
			}
			&-icon {
				width: 40px;
			}
		}
		&__close-button {
			display: block;
			color: var(--neutral-50);
			opacity: 0.7;
		}
	}

	.title {
		color: var(--gray-50);
		font-weight: 700;
		font-size: 14px;
		text-transform: uppercase;
	}

	.text {
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
		color: var(--gray-200);
	}
}
</style>
