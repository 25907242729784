<script lang="ts" setup>
const props = defineProps<{
	initialDuration: number;
	title: string;
	text: string;
	btnText: string;
	timerText: string;
}>();
const durationLeft = ref(props.initialDuration);

let intervalId: ReturnType<typeof setInterval> | null = null;

const clearToastInterval = () => {
	if (intervalId) {
		clearInterval(intervalId);
		intervalId = null;
	}
};

onMounted(() => {
	intervalId = setInterval(() => {
		if (durationLeft.value > 0) {
			durationLeft.value -= 1;
			return;
		}

		clearToastInterval();
	}, 1000);
});

onBeforeUnmount(() => {
	clearToastInterval();
});
</script>

<template>
	<div>
		<div class="title color-gray-50">
			<span>{{ title }}</span>
			<span class="timer-text color-primary-500">{{ durationLeft }}&nbsp;{{ timerText }}</span>
		</div>
		<div class="subtitle">{{ text }}</div>
		<button class="btn color-gray-950">{{ btnText }}</button>
	</div>
</template>

<style scoped lang="scss">
.title {
	font-size: 14px;
	font-weight: 700;
	line-height: 16px;
	text-transform: uppercase;
}

.subtitle {
	margin-bottom: 8px;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: var(--gray-200);
}

.btn {
	padding: 4px;
	width: 125px;
	text-align: center;
	border-radius: 4px;
	font-weight: 700;
	font-size: 14px;
	line-height: 22px;
	background: var(--primary-400);
	border: 1px solid var(--primary-400);
	outline: none;
	text-transform: uppercase;
}
</style>
<style lang="scss">
#prize-receive-timer {
	.Toastify {
		&__toast {
			padding: 8px 16px;

			@include media-breakpoint-down(lg) {
				margin: 8px 0;
			}

			&-body {
				white-space: normal;
			}
			&-icon {
				width: 40px;
			}
		}
		&__close-button {
			display: block;
			color: #fff;
			opacity: 0.7;
		}
	}
}
</style>
