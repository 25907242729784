import dayjs from "dayjs";

const usePromoInviteFriends = () => {
	const loginGuard = useLoginGuard();
	const { open } = useTaoModals();

	const endOfDayDate = dayjs().endOf("day").toString();

	const referralPopupCookie = useCookie("showReferralPopup", {
		expires: new Date(endOfDayDate),
		path: "/"
	});

	const handleOpenPopup = () => {
		loginGuard({
			success() {
				if (referralPopupCookie.value) {
					return;
				}
				referralPopupCookie.value = "onExpired";
				open("LazyOModalPromoInviteFriends");
			}
		});
	};

	return {
		handleOpenPopup
	};
};

export default usePromoInviteFriends;
