<script lang="ts" setup>
const { t } = useT();
const handleError = () => clearError({ redirect: "/" });
</script>
<template>
	<div>
		<NuxtLayout>
			<div class="error">
				<NuxtImg src="/nuxt-img/404.png" alt="404" class="error-img" format="webp" width="656" height="656" />
				<div class="content">
					<AText
						:size="20"
						class="color-secondary-400"
						:modifiers="['italic', 'medium', 'uppercase', 'center']"
						as="div"
						>{{ t("Page not found") }}</AText
					>
					<AText :size="56" class="title" :modifiers="['bold', 'uppercase', 'center']" as="h1">{{ t("404") }}</AText>
					<AText :size="16" class="color-gray-200" :modifiers="['center']" as="div">{{
						t("Welcome and thank you for visiting our free-to-play ")
					}}</AText>
					<AButton
						variant="primary"
						class="home-back"
						size="md"
						data-tid="error-return-home"
						:modifiers="['wide']"
						@click="handleError"
					>
						{{ t("Home page") }}
					</AButton>
				</div>
			</div>
		</NuxtLayout>
		<ClientOnly>
			<SupportChat />
			<OModals />
		</ClientOnly>
	</div>
</template>

<style lang="scss" scoped>
.error {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 61px 24px;
	min-height: 50vh;
	overflow: hidden;
}

.error-img {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	height: 328px;
	width: 328px;
}

.content {
	width: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 8px;
}

.home-back {
	margin-top: 16px;
	max-width: 240px;

	@include media-breakpoint-down(sm) {
		max-width: 100%;
	}
}
</style>
