const useSeasonPopupInit = () => {
	const route = useRoute();
	const loginGuard = useLoginGuard();
	const { open } = useTaoModals();
	const { seasonInitData } = useSeasonsData({ immediate: false });

	const openSeasonModal = () => {
		if (seasonInitData.value?.isEnabled && !seasonInitData.value?.isActive) {
			open("LazyOModalSeasonWelcome");
		}
	};

	const initPopup = () => {
		if (route.query.action === "login" && !!route?.query?.social) {
			openSeasonModal();
		}
	};

	onMounted(() => {
		loginGuard({
			success: () => {
				if (route?.path?.includes("game") || route?.query?.game || route?.hash?.includes("cash")) {
					return;
				}

				initPopup();
			}
		});
	});
};

export default useSeasonPopupInit;
