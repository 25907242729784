const useMagicBoxInit = () => {
	const route = useRoute();
	const loginGuard = useLoginGuard();
	const { open } = useTaoModals();
	const { playMagicAudio } = useMagicBoxAudios();

	onMounted(() => {
		loginGuard({
			success() {
				if (!route?.query?.openModal) {
					return;
				}
				if (route.query.openModal === "magic-box") {
					open("LazyOModalMagicBoxes");
					playMagicAudio();
				}
			}
		});
	});
};

export default useMagicBoxInit;
